<template>
	
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h6>TOP 10 CLASSES WITH THE HIGHEST REVENUE</h6>			
					<!-- <p>done this month <span class="text-primary">+40%</span></p>	 -->
				</a-col>
				<a-col :span="12" class="text-right">
					<a-select default-value="2023" style="width: 190px" class="text-right" @change="handleChange">
						<a-select-option value="booking">
							One to One Tutoring
						</a-select-option>
						<a-select-option value="class">
							Group Classes
						</a-select-option>
					</a-select>

					<a-button type="primary" icon="filter" class="ml-15" @click="getUserMonthlyReport">
						Filter
					</a-button>
				</a-col>
			</a-row>
		</template>

		<a-table :columns="columns" :data-source="data" :pagination="false">

			<template slot="class" slot-scope="row">
				<h6 class="m-0">
					{{ row.itemType.toLowerCase() == 'class' && row.class != null ? 
						row.class.name : 
						row.itemType.toLowerCase() == 'booking' && row.booking != null ? 
						row.booking.class.name : 
						'Unknown'}}
				</h6>
			</template>

			<template slot="classType" slot-scope="row">
				<p class="m-0">
					{{ row.itemType.toLowerCase() == 'class' ? 'Group Class' : 'One to One tutoring' }}
				</p>
			</template>

			<template slot="totalAmount" slot-scope="totalAmount">
				<h6 class="m-0">
					{{ totalAmount.toLocaleString() }}
				</h6>
			</template>

			<template slot="createdAt" slot-scope="row">
				<p class="m-0">
					{{ moment(row.createdAt).format('DD MMM YYYY')}}
				</p>
			</template>

			<!-- <template slot="completion" slot-scope="completion">
				<span class="font-bold text-muted text-sm">{{ completion.label ? completion.label : completion }}</span>
				<a-progress :percent="completion.value ? completion.value : completion" :show-info="false" size="small" :status="completion.status ? completion.status : 'normal'" />
			</template> -->

		</a-table>

	</a-card>

</template>

<script>

	export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {

				// Active button for the "Projects" table's card header radio button group.
				projectHeaderBtns: 'all',
			}
		},
	})

</script>
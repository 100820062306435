<template>

	<!-- Active Users Card -->
	<a-card :bordered="false" class="">
		<h6 class="mb-24 pt-10 pb-15 text-center">{{ title.toUpperCase() }}</h6>
		<chart-doughnut v-if="doughnutChartData.datasets[0].data.length > 0" :title="title" :height="220" :data="doughnutChartData"></chart-doughnut>
	</a-card>
	<!-- Active Users Card -->

</template>

<script>

	// Bar chart for "Active Users" card.
	import ChartDoughnut from '../Charts/Doughnut' ;

	export default ({
		components: {
			ChartDoughnut,
		},
		props: [
			'title',
			'labels',
			'values',
		],
		data() {
			return {
				// Data for bar chart.
				doughnutChartData: {
					labels: this.labels,
					datasets: [{
						label: this.title,
						backgroundColor: [
							'rgb(40, 82, 147)',
							'rgb(60, 118, 210)',
							'rgb(255, 205, 86)'
						],
						hoverOffset: 4,
						data: this.values,
					}],
				},
			}
		},

		watch: {
			values: {
				handler() {
					this.doughnutChartData = {
						labels: this.labels,
						datasets: [{
							label: this.title,
							backgroundColor: [
								'rgb(40, 82, 147)',
								'rgb(60, 118, 210)',
								'rgb(255, 205, 86)'
							],
							hoverOffset: 4,
							data: this.values,
						}],
					}
				},
			},
			deep: true,
		},

	})

</script>
<template>

	<!-- Active Users Card -->
	<a-card :bordered="false" class="dashboard-bar-chart">
		<a-row class="mb-20">
			<a-col :span="12">
				<h6 class="mb-24 pt-5">{{ 'USER REGISTRATION REPORT ' + year }}</h6>
			</a-col>

			<a-col :span="12" class="text-right">
				<a-select default-value="2023" style="width: 120px" class="text-right" @change="handleChange">
					<a-select-option value="2023">
						2023
					</a-select-option>
					<a-select-option value="2022">
						2022
					</a-select-option>
				</a-select>

				<a-button type="primary" icon="filter" class="ml-15" @click="getUserMonthlyReport">
					Filter
				</a-button>
			</a-col>
		</a-row>
		<chart-bar v-if="!loading" :title="title" :height="220" :data="barChartData"></chart-bar>
	</a-card>
	<!-- Active Users Card -->

</template>

<script>

	// Bar chart for "Active Users" card.
	import ChartBar from '../Charts/ChartBar' ;

	export default ({
		components: {
			ChartBar,
		},
		// props: [
		// 	'title',
		// 	'label',
		// 	'labels',
		// 	'values',
		// ],
					
		data() {
			return {
				title: "",
				label: "Users",
				labels: [],
				values: [],
				year: 2023,
				loading: false,
				// Data for bar chart.
				barChartData: {
					labels: this.labels,
					datasets: [{
						label: this.label,
						backgroundColor: '#fff',
						borderWidth: 0,
						borderSkipped: false,
						borderRadius: 6,
						data: this.values,
						maxBarThickness: 20,
					}, ],
				},
			}
		},

		watch: {
			values: {
				handler() {
					this.barChartData = {
						labels: this.labels,
						datasets: [{
							label: this.label,
							backgroundColor: '#fff',
							borderWidth: 0,
							borderSkipped: false,
							borderRadius: 6,
							data: this.values,
							maxBarThickness: 20,
						},],
					}
				},
			},
			deep: true,
		},

		
		created() {
			this.getUserMonthlyReport()
		},
		methods: {
			handleChange(event) {
				this.year = event;
			},

			async getUserMonthlyReport() {
				this.loading = true;

				let token = await localStorage.getItem("user_token")

				let url = `${this.$BACKEND_URL}/investor-reports/user-registration-report?year=${this.year}`;

				const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

				this.$AXIOS.get(url, config).then(async(response) => {
					if (response.status == 200) {
						this.labels = await response.data.map(rep => this.getMonthName(rep.month))
						this.values = await response.data.map(rep => rep.count)

						// console.log(this.userMonthlyReport.labels = await response.data.map(rep => this.getMonthName(rep.month)))
						this.loading = false
					}
				}).catch(async(error) => {
					console.log(error)

					this.loading = false;
					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

						this.$router.push(`/sign-in`);
					}
				});
			},


			getMonthName(num) {
				switch (num) {
					case 1:
						return 'January';
					case 2:
						return 'February';
					case 3:
						return 'March';
					case 4:
						return 'April';
					case 5:
						return 'May';
					case 6:
						return 'June';
					case 7:
						return 'July';
					case 8:
						return 'August';
					case 9:
						return 'September';
					case 10:
						return 'October';
					case 11:
						return 'November';
					case 12:
						return 'December';
				
					default:
						return "January";
				}
			}
		}
	})

</script>